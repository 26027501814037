label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 450px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f5ff;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: #441256;
  -webkit-box-shadow: #441256;
  box-shadow: #441256;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.BigLogo, .SmallLogo{
  animation: App-logo-spin infintite 2s linear;
}
@media (prefers-reduced-motion: no-preference) {
  .BigLogo , .SmallLogo{
    animation: App-logo-spin 2s;
  }
}
@keyframes App-logo-spin {
  from{
      transform: rotate(0deg);
  }
  to{
      transform: rotate(360deg);
  }
}

.SmallLogo {
  transition: transform 0.2s ease-in-out; /* add a transition for a smoother animation */
}

.SmallLogo:hover {
  transform: scale(1.08); /* increase the size of the image by 20% on hover */
}
.dialog-content {
  padding-left: 20px;
  padding-top: 20px;
  max-height: 500px; /* Set a maximum height to enable vertical scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}


.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}